import React from 'react';
import { useSsrState, useSsrEffect, useRegisterEffect } from '@issr/core';

import Filter from './filter';

import { useTenantPageFilter } from './useTenantPageFilter';
import { useParams } from 'react-router-dom';

type Props = any;

export const TenantPageFilter = (props: Props) => {
  const params = useParams();
  const registerEffect = useRegisterEffect();
  const { getTenantPage, getPageParams } = useTenantPageFilter();
  useSsrEffect(() => {
    if (!__SERVER__) {
      return;
    }
    registerEffect(getTenantPage, getPageParams(params?.slug));
  }, []);

  return <Filter {...props} />;
};
