import React, { useState, useEffect, memo } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import VerticalRibon from '../../verticalRibbon/views';
import Ribbon from '@/components/ribbon';
import { isMobile } from 'react-device-detect';
import { tenantEnvConfig } from '@/constants/tenantConfig/tenantEnvConfig';
import { getConfigByKey } from '@/features/tenantConfig/services';

const RibbonItem = memo(Ribbon, function isEqual(prevProps, nextProps) {
  return prevProps.ribbon.id === nextProps.ribbon.id;
});

const RibbonSlide = props => {
  const { page, account, t, ribbon } = props.data;
  const [ribbonSlide, setRibbonSlide] = useState([]);

  useEffect(() => {
    setRibbonSlide(ribbon);
  }, [ribbon]);

  let count = 0;
  if (page) {
    let search = page.search;
    let data = [];
    if (ribbonSlide && ribbonSlide.length > 0) {
      data = ribbonSlide.map((ribbon, index) => {
        if (isMobile) {
          ribbon.is_visible_in_ribbon_main_section = true;
        }
        if (
          (!account && ribbon.type === 5 && ribbon.items.length === 0) ||
          !ribbon.is_visible_in_ribbon_main_section
        ) {
          return;
        }
        if (ribbon.items.length > 0) count++;
        return (
          <RibbonItem
            key={ribbon?.id}
            index={index}
            title={ribbon.name}
            ribbon={ribbon}
            search={`${search}&type=${ribbon.type}`}
          />
        );
      });
    }
    return count > 0 ? (
      data
    ) : (
      <div style={{ textAlign: 'center' }}>
        {count === 0 && ribbonSlide && ribbonSlide.length > 0
          ? t('filter.txtFitlerNondata')
          : t('notDataToShow')}
      </div>
    );
  }
  return <div style={{ textAlign: 'center' }}>{t('notDataToShow')}</div>;
};

const WrapRibbon = React.memo(props => {
  const { match, ribbon } = props;
  //const tenantConfig = getTenantConfig(getConfigByKey("tenant_slug"));

  if (!ribbon) {
    return null;
  }

  if (!match.params.slug || !getConfigByKey('features.vertical_ribbon', true)) {
    return (
      <div
        className={`${tenantEnvConfig.ui_theme !== 'projectw' && 'v-home__wrap'} v-home__wrap--only`}
      >
        <RibbonSlide data={props} />
      </div>
    );
  }
  return (
    <div className="v-home__wrap">
      <Col
        xs={12}
        sm={12}
        md={8}
        lg={8}
        className="v-home__left-site"
        style={{ textDecoration: 'none' }}
      >
        <RibbonSlide data={props} />
      </Col>
      <Col className="v-home__right-site" xs={12} sm={12} md={4} lg={4}>
        <VerticalRibon />
      </Col>
    </div>
  );
});

const mapStateToProps = state => ({
  page: state.home.page,
  account: state.auth.account,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(WrapRibbon)));
