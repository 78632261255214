import React from 'react';
import { styled } from 'twin.macro';
import * as displayType from '../constants';

class BadgeNewRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { item } = this.props;
    const { type } = item;
    if (!item.is_new_release) {
      return null;
    }
    return (
      <DivStyle
        className={`ribbonNewRelease ${
          (type === displayType.SHOW_TYPE || type === displayType.EPISODE_TYPE) &&
          'ribbonNewRelease--epg'
        }`}
        theme={{ main: 'Episode' }}
      >
        <p>New</p>
      </DivStyle>
    );
  }
}

export const DivStyle = styled.div`
  &.ribbonNewRelease {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 8rem;
    height: 3.75rem;
    background: #00b2ff;
    margin-left: -1.75rem;
    transform: rotate(333deg);
    margin-top: -2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    left: -0.25rem;
    color: #fff;
    font-weight: 600;
    p {
      margin: 15px 0 0 2rem;
      font-size: 1rem;
    }
    &--epg {
      &::before {
        content: '${props => props.theme.main}';
        position: absolute;
        bottom: -0.65rem;
        width: 50%;
        font-size: 0.8125rem;
        height: auto;
        background: #fff;
        left: 30%;
        color: #2574d4;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (min-width: 992px) {
    &.ribbonNewRelease {
      top: auto;
      left: auto;
      margin-top: 0;
      margin-left: 0;
      bottom: 0;
      width: 12rem;
      height: 4.5rem;
      margin-right: -1.75rem;
      margin-bottom: -2rem;
      justify-content: center;
      align-items: flex-start;
      right: -1.25rem;
      p {
        margin: 5px 0 0 2.5rem;
        font-size: 1.375rem;
      }
      &--epg {
        &::before {
          top: -0.65rem;
          bottom: auto;
          width: 37%;
          right: 15%;
          left: auto;
        }
      }
    }
  }
`;
export default BadgeNewRelease;
