import React from 'react';
import BodyOdv from './BodyOdv';
import BodySctv from './BodySctv';
import BodyW from './BodyW';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';

export default class PanelBody extends React.Component {
  render() {
    let Component;
    switch (getConfigByKey('ui_theme')) {
      case 'sctv':
        Component = BodySctv;
        break;
      case 'projectw':
        Component = BodyW;
        break;
      default:
        Component = BodyOdv;
    }

    return <Component {...this.props} />;
  }
}
