import React from 'react';
import PropTypes from 'prop-types';
import * as displayType from '../../constants';
import homePageId from '@/constants/ids/homePageId';
import UpgradeButton from './upgrade-button';
import { withTranslation } from 'react-i18next';
import { styled } from 'twin.macro';

class WatchButton extends React.Component {
  render() {
    const { t, item, index, account, payperview } = this.props;
    if (item.type === displayType.CHANNEL_TYPE || item.type === displayType.CURATED_LIVE) {
      return (
        <StyledButton
          data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
          className="v-slide--btn abcd"
        >
          <span>{t('ribbon.slide.txtWatchLive')}</span>
        </StyledButton>
      );
    }
    if (item.num_first_episode_preview > 0 || !item.is_premium || payperview) {
      return (
        <StyledButton
          data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
          className="v-slide--btn"
        >
          <span>{t('ribbon.slide.watch')}</span>
        </StyledButton>
      );
    }
    return <UpgradeButton account={account} item={item} index={index} {...this.props} />;
  }
}

const StyledButton = styled.button`
  &.v-slide {
    &--btn {
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
      font-size: 1.5em;
      text-transform: uppercase;
      padding: 0.45em 1.5em;
      margin-top: 1.25rem;
      border-radius: 0.25em;
      border: none !important;
      outline: none !important;
      font-weight: bold;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    &.v-slide {
      &--btn {
        margin-bottom: 1.5rem;
        font-size: 1.125em;
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

WatchButton.propTypes = {
  item: PropTypes.any,
  account: PropTypes.any,
  index: PropTypes.number,
};

export default withTranslation()(WatchButton);
