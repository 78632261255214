import { SeoHelmet } from '@/shared/components/seo/SeoHelmet';
import React, { useState } from 'react';
import HomePage from '@/shared/features/homepage/views/homePage';
import { useAppSelector } from '@/shared/core/hook/appHook';
import { homePageSlugSelector } from '@/shared/features/tenantConfig/selector';
import { tenantPageByIDSelector } from '@/shared/features/homepage/selector';
import { useParams } from 'react-router-dom';
type Props = {};

export const TenantPage = (props: Props) => {
  const homepageSlug = useAppSelector(homePageSlugSelector);
  const params = useParams();
  const page = useAppSelector(tenantPageByIDSelector(params?.slug ?? homepageSlug));
  return (
    <>
      <SeoHelmet seo={page?.seo} />
      <HomePage {...props} page={page} />
    </>
  );
};
