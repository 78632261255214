import * as homePageApi from '../../api/homePageApi';
import queryString from 'query-string';

function getRibbonDetail(pageSlug, ribbonSlug, search) {
  const queryParams = queryString.parse(search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (![5, 9].includes(parseInt(queryParams?.type, 10))) {
        homePageApi
          .getRibbonDetail(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        homePageApi
          .getRibbonDetailRecent(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };
}

export { getRibbonDetail };
