import React from 'react';
import RibbonItem from '../ribbonItem';
import Ribbons from '../../styled';
import homePageId from '@/constants/ids/homePageId';
import { updateStatusRemove } from '@/features/homepage/services';
import { connect } from 'react-redux';
import * as display from '../../constants';
import { isMobile } from 'react-device-detect';
import { styled } from 'twin.macro';
import { Row } from 'react-bootstrap';
import SingleRibbon from './single-ribbon';
import RibbonThumbnail from './ribbon-thumbnail';
import ActorRibbon from './ribbon-actor';
import SecondaryBanner from '../secondary-banner';

class RibbonW extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      viewMore: 24,
      ribbonItems: [],
    };
  }

  componentWillMount() {
    if (isMobile) {
      this.setState({
        viewMore: 15,
      });
    }
  }

  componentDidMount() {
    const { ribbon } = this.props;
    this.setState({
      ribbonItems: ribbon.items || [],
    });
  }

  componentWillReceiveProps(nextProps) {
    const { ribbon } = nextProps;
    if (ribbon.id !== this.props.ribbon.id) {
      this.setState({
        ribbonItems: ribbon.items || [],
      });
    }
  }

  handleOpen(items = {}) {
    let data = {
      isOpen: true,
      items,
    };
    this.props.dpUpdateStatusRemove(data);
  }

  handleRemoveRecent(data) {
    const { ribbonItems } = this.state;
    let index = ribbonItems.findIndex(item => item.id === data.items.id);
    ribbonItems.splice(index, 1);
    this.setState({
      ribbonItems: ribbonItems,
    });
  }

  _renderRibbonItem(ribbon, ribbonIndex) {
    const { ribbonItems, viewMore } = this.state;
    this.countRibbonShow = 0;
    if (ribbonItems && ribbonItems.length === 0) return null;

    this.countRibbonShow++;
    return ribbonItems.slice(0, viewMore).map((item, index) => {
      delete item.title;
      return (
        <RibbonItem
          item={item}
          ribbon={ribbon}
          ribbonIndex={ribbonIndex}
          index={index}
          key={index}
          lazyLoad
          handleOpen={() => this.handleOpen(item)}
        />
      );
    });
  }

  _renderHead() {
    const { title, index, ribbon } = this.props;
    if (ribbon.display_type !== 2) return;
    return (
      <div className="ribbonHead">
        <h3 data-testid={`${homePageId.ribbonId.titleLbl}-${index}`} className="title">
          {title}
        </h3>
      </div>
    );
  }

  render() {
    const { activeSlide } = this.state;
    const { title, index, ribbon } = this.props;

    const displayType = ribbon.display_type;
    let responsiveData = [
      { breakpoint: 480, settings: { slidesToShow: 1.75, slidesToScroll: 2 } },
      { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
    ];
    let RibbonComp;

    switch (displayType) {
      case 2: // poster
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { slidesToShow: 5.35, slidesToScroll: 5 } },
          { breakpoint: 1660, settings: { slidesToShow: 5.35, slidesToScroll: 5 } },
          { breakpoint: 1980, settings: { slidesToShow: 6.35, slidesToScroll: 6 } },
        ];
        RibbonComp = SingleRibbon;
        break;
      case 3: // headerless thumbnail
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1660, settings: { slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1980, settings: { slidesToShow: 5, slidesToScroll: 5 } },
        ];
        RibbonComp = SingleRibbon;
        break;
      case 4: // thumbnail group
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { rows: 2, slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1660, settings: { rows: 2, slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1980, settings: { rows: 2, slidesToShow: 5.25, slidesToScroll: 5 } },
        ];
        RibbonComp = RibbonThumbnail;
        break;
      case 5: // poster group
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { slidesToShow: 4.25, slidesToScroll: 4 } },
          { breakpoint: 1660, settings: { slidesToShow: 5.25, slidesToScroll: 5 } },
          { breakpoint: 1980, settings: { slidesToShow: 6.25, slidesToScroll: 6 } },
        ];
        RibbonComp = RibbonThumbnail;
        break;
      case 6: // poster group with image
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1660, settings: { slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1980, settings: { slidesToShow: 5, slidesToScroll: 5 } },
        ];
        RibbonComp = ActorRibbon;
        break;
      case 7: // secondary banner
        responsiveData = [
          { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 687, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 1350, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 1660, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 1980, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ];
        RibbonComp = SecondaryBanner;
        break;
      default:
        responsiveData = [
          ...responsiveData,
          { breakpoint: 1350, settings: { slidesToShow: 3, slidesToScroll: 3 } },
          { breakpoint: 1660, settings: { slidesToShow: 4, slidesToScroll: 4 } },
          { breakpoint: 1980, settings: { slidesToShow: 4.5, slidesToScroll: 4 } },
        ];
        RibbonComp = SingleRibbon;
    }

    function PrevButton({ onClick }) {
      if (activeSlide === 0) return null;
      return (
        <a
          data-testid={`${homePageId.ribbonId.scrollPrevBtn}-${index}`}
          onClick={onClick}
          className="prevButton"
          style={{ top: '40%' }}
        >
          <i className="fa fa-chevron-left prevButtonIcon" />
        </a>
      );
    }

    function NextButton({ onClick }) {
      return (
        <a
          data-testid={`${homePageId.ribbonId.scrollNextBtn}-${index}`}
          onClick={onClick}
          className="nextButton"
          style={{ top: '40%' }}
        >
          <i className="fa fa-chevron-right nextButtonIcon" />
        </a>
      );
    }

    const sliderSettings = {
      infinite: false,
      lazyLoad: true,
      speed: 500,
      swipeToSlide: false,
      initialSlide: 0,
      mobileFirst: true,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      afterChange: activeSlide => this.setState({ activeSlide }),
      responsive: responsiveData,
    };
    const ribbonItems = ribbon.items || [];

    return (
      <StyledRibbons className="ribbonContainer" displayType={displayType}>
        {this._renderHead(index)}
        {ribbonItems.length === 0 ? null : (
          <Row className={`sliderBlock slider-${display[`CLASS_${displayType || 1}`]}`}>
            <RibbonComp
              sliderSettings={sliderSettings}
              ribbon={ribbon}
              renderRibbonItem={() => this._renderRibbonItem(ribbon, index)}
              title={title}
              index={index}
            />
          </Row>
        )}
      </StyledRibbons>
    );
  }
}

const StyledRibbons = styled(Ribbons)`
  padding-left: 15px;
  & .middle {
    display: flex;
    align-items: center;
  }
  & .sliderBlock {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    position: relative;
    &:last-child {
      padding-right: 0;
    }
  }
  & .bg-dark {
    top: 0;
    left: 0;
    padding: 30px 0 30px 45px;
    &--left {
      background-image: linear-gradient(90deg, #dadada 0%, rgba(218, 218, 218, 0) 100%);
    }
    &--right {
      background-image: linear-gradient(-90deg, #dadada 0%, rgba(218, 218, 218, 0) 100%);
      padding-right: 45px !important;
    }
  }
  @media (min-width: 768px) {
    padding-left: ${({ displayType }) => {
      return displayType === 6 ? 0 : '45px';
    }};
    & .prevButton,
    & .nextButton {
      width: 50px !important;
      height: 50px !important;
      visibility: hidden;
      background-color: white !important;
      border-radius: 50% !important;
      .nextButtonIcon,
      .prevButtonIcon {
        color: #121212 !important;
        font-size: 0.9rem !important;
      }
    }
  }
`;

RibbonW.defaultProps = {
  title: '',
  ribbon: [],
  isAllButtonDisplay: true,
  onPressViewAll: () => {},
};

const mapDispatchToProps = {
  dpUpdateStatusRemove: data => updateStatusRemove(data),
};

export default connect(null, mapDispatchToProps)(RibbonW);
