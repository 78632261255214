import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ActorThumbnail from '../actor-thumbnail';
import { ContentSlider } from '@/shared/components/slider/ContentSlider';

export default class ActorRibbon extends React.Component {
  render() {
    const { title, ribbon, renderRibbonItem } = this.props;

    return (
      <React.Fragment>
        <Col sm={12} md={7}>
          <ContentSlider {...this.props.sliderSettings} className="bg-dark bg-dark--left">
            {renderRibbonItem()}
          </ContentSlider>
        </Col>
        <Col sm={12} md={5} style={{ paddingRight: 0 }}>
          <ActorThumbnail ribbon={ribbon} title={title} contentClass="bg-dark bg-dark--right" />
        </Col>
      </React.Fragment>
    );
  }
}

ActorRibbon.propTypes = {
  sliderSettings: PropTypes.object,
  ribbon: PropTypes.object,
  renderRibbonItem: PropTypes.func,
  title: PropTypes.string,
  index: PropTypes.number,
};
