import React from 'react';
import { styled } from 'twin.macro';
import { LineClampStyled } from '../../../features/landingpage/styles/line-clamp';

export default class GroupThumbnail extends React.Component {
  render() {
    // eslint-disable-next-line camelcase
    const {
      ribbon: { display_type, description, url, thumbnail },
      title,
    } = this.props;
    // eslint-disable-next-line camelcase
    if (display_type !== 4 && display_type !== 5) return null;
    return (
      <StyledDiv>
        <div className="image-wrapper">
          <img
            loading="lazy"
            src="http://d2ryy79pkcqyaa.cloudfront.net/odv-stag/tenants/cd99c164-11b0-4ce2-ada4-4b8c6dd2d4ad/auto_image_youtube_040cbc10_9a339c0d.jpg?width=1920&version=6&s3_origin=https%3A%2F%2Fodv-stag.s3-ap-southeast-1.amazonaws.com"
          />
        </div>
        <div className="content-wrapper">
          <h3>{title}</h3>
          <LineClampStyled className="desc line-clamp-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.
            Aliquam erat volutpat. Donec placerat nisl magna, et faucibus arcu condimentum sed.
            Lorem ipsum dolor sit amet.
            <br />
            Consectetur adipiscing elit. Aliquam at porttitor sem. Aliquam erat volutpat. Donec
            placerat nisl magna, et faucibus arcu condimentum sed.
          </LineClampStyled>
          <button className="btn">Browse all</button>
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  position: relative;
  & .image-wrapper,
  & .image-wrapper img,
  & .content-wrapper {
    border-radius: 10px;
  }
  & .image-wrapper {
    padding-top: calc(9 / 16 * 100%);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  & .content-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 45px;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    & h3 {
      font-size: 30px;
      font-weight: bold;
      color: #404040;
      text-decoration: none solid rgb(64, 64, 64);
      text-transform: capitalize;
    }
    & .desc {
      font-size: 14px;
    }
    & .btn {
      background-color: #ffffff;
      color: #404040;
      text-decoration: none solid rgb(64, 64, 64);
      font-size: 14px;
      border-radius: 2em;
      border: none;
      box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
      float: right;
      margin-top: 20px;
      margin-right: 10px;
      margin-bottom: 25px;
    }
  }
`;
