import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { getStaticImages } from '../services';
import * as filterServices from '@/shared/components/filter/services';
import './homePage.css';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { loading } from '@/shared/features/loadingPage/services';
import { withTranslation } from 'react-i18next';
import { setUserProperties } from '@/services/analytics/SetUserProperties';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { getTenantConfig } from '@/shared/utils/utils';
import { RowStyled } from './styled';
import { isMobileOnly } from 'react-device-detect';
import YoutubePage from '../../youtubePage/views';
import OverlayLoading from '@/shared/components/loading/overlayLoading';
import NewSlickSlider from '@/shared/components/newSlider';
import WrapRibbon from '../components/wrapRibbon';
import { tenantPageByIDSelector } from '../selector';
import { homePageSlugSelector } from '../../tenantConfig/selector';
import { getKeyReactByProfileId } from '@/core/method/accountMethod';
import { withKeyByAccountId } from '@/core/hoc/withKeyByAccountId';
import { Helmet } from 'react-helmet-async';
import { TenantPageFilter } from '@/shared/components/filter/TenantPageFilter';

const AdsBanner = loadable(() => import('@/shared/components/adsBanner/views/index'));
const SubCategory = loadable(() => import('../components/subCategory'));
const FilterContent = loadable(() => import('@/shared/components/filter/filter'));

class HomePage extends PureComponent {
  static propTypes = {
    account: PropTypes.object,
    menu: PropTypes.any,
    page: PropTypes.any,
    isLoadingPage: PropTypes.any,
    dispatchGetMenu: PropTypes.func,
    dispatchGetPage: PropTypes.func,
    dispatchGetMenuPage: PropTypes.func,
    showLoginAction: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.lastSlug = '';
    // this.isHomepage = true;

    this.state = {
      privacyPolicyModalShow: false,
      termOfUseModalShow: false,
      count: 1,
      ribbons: null,
    };

    this.filterRef = React.createRef();
  }

  componentDidMount() {
    setUserProperties();
    const { state } = this.props.location;
    const { dispatchLoadingPage, page, dpGetStaticImages } = this.props;
    let params = queryString.parse(this.props.location.search);
    window.document.body.classList.add('v-homePage');
    dpGetStaticImages();
    dispatchLoadingPage(false);

    if (state && state.login && !this.props.account) {
      this.props.showLoginAction();
    }
    const token = params ? params.code : null;
    if (token) this.props.showLoginAction();
    if (page?.seo?.title) {
      window.document.title = page?.seo?.title;
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.document.body.classList.remove('v-homePage');
  }

  render() {
    const { menu, account } = this.props;
    const profileId = getKeyReactByProfileId.call(account);

    if (menu.length && menu[0].display_style === 'YOUTUBE') {
      return <YoutubePage />;
    }
    const { page = {}, isLoadingPage } = this.props;
    const { ribbons = [] } = page;
    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    return (
      <React.Fragment>
        <RowStyled>
          <div className="v-home--container m">
            {isLoadingPage && <OverlayLoading />}
            <NewSlickSlider showLoginAction={href => this.props.showLoginAction(href)} />
            <SubCategory />
            <TenantPageFilter
              ref={this.filterRef}
              page={page}
              filterTenant
              visible={tenantConfig.HOME_RENDER_FILTER}
              // key={profileId}
            />
            <WrapRibbon ribbon={ribbons} />
          </div>
        </RowStyled>
        <AdsBanner position="bottom" />
      </React.Fragment>
    );
  }
}

HomePage.defaultProps = {
  showLoginAction: param => {},
};
HomePage.propTypes = {
  account: PropTypes.object,
  menu: PropTypes.any,
  page: PropTypes.any,
  isLoadingPage: PropTypes.any,
  dispatchGetMenu: PropTypes.func,
  dispatchGetPage: PropTypes.func,
  dispatchGetMenuPage: PropTypes.func,
  showLoginAction: PropTypes.func,
};

HomePage.serverFetch = [pageSlug => filterServices.getFilterData(pageSlug)];

const mapStateToProps = (state, { match }) => {
  return {
    menu: state?.tenantConfig?.platformConfig?.tenant_pages ?? [],
    // page: tenantPageByIDSelector(match?.params?.slug ?? homePageSlugSelector(state))(state),

    isLoadingPage: state.home.isLoadingPage,
    account: state.auth.account,
  };
};

const mapDispatchToProps = {
  dispatchLoadingPage: value => loading(value),
  dpGetStaticImages: () => getStaticImages(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withKeyByAccountId(HomePage))),
);
