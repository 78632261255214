import React from 'react';
import PropTypes from 'prop-types';
import { contentTypes } from '../../../../utils/utils';
import { styled } from 'twin.macro';
import AgeRatingBadge from '@/shared/components/badges/AgeRatingBadge';
import { getCategoryName } from '@/core/method/contentMethod';
import LiveBadge from '@/shared/components/badges/LiveBadge';

export default class Type extends React.Component {
  render() {
    const { entityDetail, payperview } = this.props;
    const isLive = entityDetail.type === 5 || entityDetail.type === 7;
    // eslint-disable-next-line camelcase
    let statusText = getCategoryName.call(entityDetail);
    return (
      <StyledComp className="v-slide--type">
        <AgeRatingBadge className="mr medium" content={entityDetail} />
        {isLive ? (
          <LiveBadge content={entityDetail} size="large" />
        ) : (
          <span className="v-slide--title">{statusText}</span>
        )}
        {/* {(payperview || !entityDetail.has_free_content) && (
          <i className='fa fa-star v-slide--fa' aria-hidden='true'>
            <span className='v-slide--vip-txt'> VIP</span>
          </i>
        )} */}
        <img
          loading="lazy"
          className={`v-slide--icon-ytb ${!entityDetail.video_source && 'hide'}`}
          src={require('@/assets/img/youtube.png')}
          width="80"
          height="25"
        />
      </StyledComp>
    );
  }
}

const StyledComp = styled.div`
  &.v-slide {
    &--type {
      color: #e8b600;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      align-items: center;
    }
  }
  & .v-slide {
    &--live {
      width: 4.25rem;
      font-weight: 700;
      font-size: 0.875rem;
      padding: 0.025rem 0 0.125rem 0.65rem;
      text-transform: uppercase;
      color: #ff0000;
      background: #fff;
      border-radius: 20px;
      margin-right: 1rem;
    }
    &--title {
      color: #7d7d7d;
      text-transform: capitalize;
      margin: 0;
    }
    &--icon {
      &-ytb {
        width: 4.5rem;
        margin-left: 0;
        height: 100%;
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .v-slide {
      &--title {
        color: white;
        margin-right: 1.125em;
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

Type.propTypes = {
  entityDetail: PropTypes.any,
};
