import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import * as displayType from '../../constants';
import pushAnalytics from '@/services/analytics/Analytics';
import { Link } from 'react-router-dom';
import homePageId from '@/constants/ids/homePageId';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class UpgradeButton extends React.Component {
  render() {
    const { t, account, history, language, item, index } = this.props;
    let callbackUrl = `/landing/${item.slug}`;

    if (
      item.type === displayType.CHANNEL_TYPE ||
      item.type === displayType.CURATED_LIVE ||
      item.video_source === 1
    ) {
      callbackUrl = `/detail/${item.slug}`;
    }
    let param = {
      upgrade: true,
      callbackUrl: callbackUrl,
      svod: 1,
    };

    if (
      item.is_watchable ||
      item.has_free_content ||
      (account && account.profile && account.profile.subscription_plan_info)
    ) {
      return (
        <Link to={`/landing/${item.slug}`}>
          <StyledButton
            data-testid={`${homePageId.bannerId.contentPlayBtn}-${index}`}
            className="v-slide--btn v-slide--btn-watch"
          >
            <span>{t('ribbon.slide.watch')}</span>
          </StyledButton>
        </Link>
      );
    }
    return (
      <StyledButton
        className="v-slide--btn v-slide--btn-upgrade"
        onClick={event => {
          event.stopPropagation();
          pushAnalytics('click', {
            content_type: 'button',
            item_name: 'UPGRADE TO WATCH',
            item_id: item.slug || '',
          });
          // history.push(`/landing/${item.slug}?ppv=true`, param);
          history.push(`/landing/${item.slug}`, param);
        }}
      >
        <span>{t('landingPage.buttonUpgrade')}</span>
      </StyledButton>
    );
  }
}

const StyledButton = styled.button`
  &.v-slide {
    &--btn {
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
      font-size: 1.5em;
      text-transform: uppercase;
      padding: 0.45em 1.5em;
      margin-top: 1.25rem;
      border-radius: 0.25em;
      border: none !important;
      outline: none !important;
      font-weight: bold;
      &-upgrade {
        background-image: ${({ theme }) => theme.newSlide.btnUpgrade};
      }
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

UpgradeButton.propTypes = {
  item: PropTypes.any,
  account: PropTypes.any,
  index: PropTypes.number,
};

const mapStateToProps = state => ({
  language: state.root.language,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(UpgradeButton)));
