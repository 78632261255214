import { useAppDispatch, useAppSelector } from '@/shared/core/hook/appHook';
import { homePageSlugSelector } from '@/shared/features/tenantConfig/selector';
import { useLocation, useParams } from 'react-router-dom';
import { getPage } from './services';

export const useTenantPageFilter = () => {
  const { pathname } = useLocation();
  const homePageSlug = useAppSelector(homePageSlugSelector);

  const dispatch = useAppDispatch();

  const getPageParams = (slug: string) => ({
    slug: !slug && pathname === '/' ? homePageSlug : slug,
    apply_filter_for_side_navigation_section: false,
    limit: 10,
  });

  const getTenantPage = (params: ReturnType<typeof getPageParams>) => {
    return getPage(params)(dispatch);
  };

  return { getTenantPage, getPageParams };
};
