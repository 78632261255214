import React from 'react';
import { styled } from 'twin.macro';
import { Panel } from 'react-bootstrap';

import './index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import homePageId from '../../constants/ids/homePageId';
import pushAnalytics from './../../services/analytics/Analytics';
import * as displayType from './constants';
import { contentTypes, getTenantConfig } from '../../utils/utils';
import { getConfigByKey } from '../../features/tenantConfig/services';
import classnames from 'classnames';
import Heading from './component/heading';
import PanelBody from './component/body';
import { isLogged } from '@/core/method/authMethod';
import { setOpenRequestSignIn } from '@/shared/components/global/globalServices';
import { CURATED_LIVE, TYPE_NO_LANDING_PAGE } from '@/core/constants/contentConstants';
import PopupUpgrade from '@/shared/features/landingpage/components/popupUpgrade';
import { getBackdrop, getBanner19_6, getPoster, needUpgrade } from '@/core/method/contentMethod';
import { ContentSlider } from '@/shared/components/slider/ContentSlider';

const RowStyled = styled.div`
  position: relative;
  .slick-slide > div {
    background-color: red;
    max-height: 100%;
    height: 100%;
  }
  &.v-slide {
    &--container {
      min-height: 25vh;
      ul {
        display: none !important;
        &.slick-dots li.slick-active {
          background: ${({ theme }) => theme.primaryColor};
        }
      }
      &.fullscreen {
        margin-bottom: -30vh;
        & .v-slide__thumbnail {
          height: 100vh;
        }
        & .v-slide--container {
          position: absolute;
        }
        & .vignette {
          background-image: linear-gradient(
            to bottom,
            rgba(6, 6, 6, 0) 0,
            rgba(6, 6, 6, 0.15) 15%,
            rgba(6, 6, 6, 0.35) 29%,
            rgba(6, 6, 6, 0.58) 44%,
            #000000 68%,
            #000000 100%
          );
          background-size: 100% 100%;
          background-position: 0 top;
          background-repeat: repeat-x;
          background-color: transparent;
          width: 100%;
          height: 14.7vw;
          top: auto;
          bottom: -1px;
          opacity: 1;
          &.vignette-layer {
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
          }
        }
      }
      @media (max-width: 920px) {
        &.fullscreen {
          margin-bottom: -8vh;
          & .v-slide__thumbnail {
            padding-top: 100%;
            height: 60vh;
          }
        }
      }
    }
  }
  font-size: 1rem;
  p {
    margin: 0;
  }
  .v-slide {
    &--touchBanner {
      padding-top: calc(9 / 16 * 100%);
      visibility: visible;
      opacity: 1;
      & .prevButtonIcon,
      & .nextButtonIcon {
        color: ${({ theme }) => theme.primaryColor};
      }
    }
    &--slide {
      border-radius: 0;
      border: none;
      margin-bottom: 0;
      display: block !important;
      cursor: pointer;
      background-color: transparent;
    }
    &--fa {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    font-size: 1rem;
    .slick-slide > div {
      background-color: red;
      max-height: 100%;
      height: 100%;
    }
    &.v-slide {
      &--container {
        min-height: 30vh;
        ul {
          display: block !important;
        }

        &.fullscreen {
          .v-slide__body {
            bottom: 14.7vw;
            left: 30px;
          }
          .slick-dots {
            top: 70%;
          }
        }
      }
    }
    .v-slide {
      &--touchBanner {
        padding-top: calc(6 / 19 * 100%);
        visibility: hidden;
        opacity: 0;
      }
      &--info {
        padding: 0 2% 0 45px;
      }
      &--slide {
        position: relative;
        height: 100%;
      }
    }
  }

  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

const settings = {
  dots: true, // todo set to false if tenant is Project W
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  adaptiveHeight: false,
  autoplay: true,
  cssEase: 'linear',
  autoplaySpeed: 4000,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
};

class NewSlickSlider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      banners: [],
      contentCheckUpgrade: null,
    };
  }

  componentDidMount() {
    const { page } = this.props;
    if (page && page.banners) {
      this.setState({
        banners: page.banners,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this.setState({
        banners: nextProps.page.banners,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  setContentCheckUpgrade = value => {
    this.setState({ contentCheckUpgrade: value });
  };

  isOpenUpgrade = function () {
    return this.state.contentCheckUpgrade !== null;
  };

  closeUpgrade = function () {
    this.setState({ contentCheckUpgrade: null });
  };

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  _hrefUrl(item, index) {
    if (!item.slug) {
      return;
    }
    const { history, auth, dpSetOpenRequestSignIn, account, paidContents } = this.props;
    let statusText = contentTypes(item);
    let linkPlay = `/landing/${item.slug}`;
    pushAnalytics('select_content', {
      content_type: statusText,
      item_id: item.slug,
      widget: 'Header',
      order: index + 1,
    });
    if (item.video_source === 1 && item.type === displayType.SHOW_TYPE) {
      return history.push(linkPlay);
    }
    if (TYPE_NO_LANDING_PAGE.includes(item.type) || item.video_source === 1) {
      if (getConfigByKey('features.requestSignInFeature') && !isLogged.call(auth)) {
        return dpSetOpenRequestSignIn(true);
      }
      if (needUpgrade.call(item, { account, paidContents })) {
        return this.setContentCheckUpgrade(item);
      }
      return history.push(`/detail/${item.slug}`);
    }
    history.push(linkPlay);
  }

  render() {
    const { account, extraBanners } = this.props;

    const { banners: orignBanners, width, contentCheckUpgrade } = this.state;
    // const bannerExtra = orignBanners.filter(banner => !banner.id);
    const banners = [...orignBanners.slice(0, 10), ...extraBanners];
    let img = '';

    const tenantConfig = getTenantConfig(getConfigByKey('tenant_slug'));
    if (banners && Array.isArray(banners) && !banners.length) return null;

    return (
      <RowStyled
        className={classnames(
          'topSlider_home v-slide--container',
          tenantConfig && tenantConfig.BANNER_STYLE,
        )}
      >
        <ContentSlider {...settings}>
          {banners.map((item, index) => {
            if (account && account.profile.subscription_plan_info) {
              item.is_premium = false;
              item.has_free_content = true;
            }
            const { banner, backdrop, poster } = item.images;
            img = getBanner19_6.call(item);
            if (width <= 992) {
              img = getBackdrop.call(item);
            }
            const isSctvTenant = getConfigByKey('tenant_slug') === 'sctv';
            if (isSctvTenant) {
              img = getBackdrop.call(item);
              if (isMobile || width <= 992) {
                img = getPoster.call(item);
              }
            }

            return (
              <Panel
                key={index}
                className="v-slide--slide"
                onClick={() => this._hrefUrl(item, index)}
              >
                <Heading img={img} data={item} />
                {item.id && (
                  <PanelBody
                    data={item}
                    index={index}
                    showLoginAction={this.props.showLoginAction}
                  />
                )}
              </Panel>
            );
          })}
        </ContentSlider>
        {this.isOpenUpgrade() && (
          <PopupUpgrade
            modalShow={true}
            hideModal={() => this.closeUpgrade()}
            entity={contentCheckUpgrade}
          />
        )}
      </RowStyled>
    );
  }
}

NewSlickSlider.defaultProps = {
  showLoginAction: () => {},
};
NewSlickSlider.propTypes = {
  account: PropTypes.object,
  page: PropTypes.object,
  showLoginAction: PropTypes.func,
  isLoadingPage: PropTypes.any,
};

const mapStateToProps = state => ({
  page: state.home.page,
  account: state.auth.account,
  isLoadingPage: state.home.isLoadingPage,
  language: state.root.language,
  extraBanners: state.home.extraBanners,
  auth: state.auth,
  paidContents: state.auth.paidContents,
});
const mapDispatchToProps = {
  dpSetOpenRequestSignIn: value => setOpenRequestSignIn(value),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSlickSlider));

function PrevButton({ onClick }) {
  return (
    <div
      data-testid={homePageId.bannerId.prevContentBtn}
      onClick={() => {
        pushAnalytics('click', {
          content_type: 'button',
          item_name: 'left',
        });
      }}
      className="v-slide--prevButton v-slide--touchBanner"
    >
      <i className="fa fa-chevron-left prevButtonIcon" onClick={onClick} />
    </div>
  );
}

function NextButton({ onClick }) {
  return (
    <div
      data-testid={homePageId.bannerId.nextContentBtn}
      onClick={() => {
        pushAnalytics('click', {
          content_type: 'button',
          item_name: 'right',
        });
      }}
      className="v-slide--nextButton v-slide--touchBanner"
    >
      <i className="fa fa-chevron-right nextButtonIcon" onClick={onClick} />
    </div>
  );
}
