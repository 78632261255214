import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { ContentSlider } from '@/shared/components/slider/ContentSlider';
export default class SingleRibbon extends React.Component {
  render() {
    const { renderRibbonItem } = this.props;

    return (
      <Col sm={12}>
        <ContentSlider {...this.props.sliderSettings}>{renderRibbonItem()}</ContentSlider>
      </Col>
    );
  }
}

SingleRibbon.propTypes = {
  sliderSettings: PropTypes.object,
  ribbon: PropTypes.object,
  renderRibbonItem: PropTypes.func,
  index: PropTypes.number,
};
