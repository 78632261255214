import React from 'react';
import RibbonOdv from './components/ribbonOdv';
import RibbonW from './components/ribbonW';
import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';

export default class Ribbon extends React.Component {
  render() {
    let Component;
    switch (tenantEnvConfig.ui_theme) {
      case 'sctv':
        Component = RibbonOdv;
        break;
      case 'projectw':
        Component = RibbonW;
        break;
      default:
        Component = RibbonOdv;
    }
    return <Component {...this.props} />;
  }
}
