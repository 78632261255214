import React from 'react';
import { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import BadgeNewRelease from '../newReleaseBadge';

export default class Heading extends React.PureComponent {
  render() {
    const { img, data } = this.props;
    return (
      <StyledComp className="v-slide__header">
        <BadgeNewRelease item={data} />
        <div className="v-slide__thumbnail">
          <img className="v-slide--thumbnail" src={img} />
          <div className="vignette vignette-layer" />
        </div>
      </StyledComp>
    );
  }
}

const StyledComp = styled(Panel.Heading)`
  &.v-slide__header {
    padding: 0;
    border-radius: 0;
    border: none;
  }
  & .v-slide {
    &__thumbnail {
      cursor: pointer;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      background-image: linear-gradient(#fff, #000);
      &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 125%);
        z-index: 1;
      }
    }
    &--thumbnail {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    .v-slide__thumbnail {
      padding-top: calc(6 / 19 * 100%);
      &:before {
        background-image: linear-gradient(90deg, #000 0%, rgba(255, 255, 255, 0) 30%);
      }
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1440px) {
  }
  @media (min-width: 1680px) {
  }
`;

Heading.propTypes = {
  data: PropTypes.any,
  img: PropTypes.string,
};
